<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center" style="margin-bottom: -1.5vw;" :style="[bgColor === 'purple' ? { backgroundColor: '#221551' } : bgColor === 'black' ? { backgroundColor: '#000000'} : { backgroundColor: '#FFFFFF' }]">
	<!-- <v-container fluid class="d-flex align-center pa-0 justify-center" style="margin-bottom: -1.5vw;" :style="{backgroundColor : parsedBackgroundColor}"> -->
		<v-row class="d-flex flex-wrap duo-custom-template">

			<v-col cols="12" style="margin-top: 1vw;" v-if="!isPreview">
				<v-row class="d-flex justify-space-between align-center">
					<v-col class="duo-custom justify-left align-center">
						<div style="font-family: AirNZ Sans Regular !important;" :style="[bgColor === 'white' ? { color: '#000000' } : { color: '#FFFFFF' }]" class="pl-10"><h2>{{ title }}</h2></div>
					</v-col>
					<v-col cols="auto" class="justify-right">
						<v-row class="d-flex justify-right no-gutters mr-10">
							<v-col class="duo-custom pr-3 d-flex align-center">
								<div v-html="page.valueMap.headerIconTitle" class="text-right align-center" style="font-family: AirNZ Sans Regular !important;" :style="[bgColor === 'white' ? { color: '#000000' } : { color: '#FFFFFF' }]"></div>
							</v-col>
							<v-col cols="auto" class="d-flex align-end">
								<v-avatar v-if="$root.isBlank(page.valueMap.headerIcon)" :color="parsedHeaderIconBackgroundColor" size="58" class="ml-auto">
									<v-icon :color="parsedHeaderIconColor" size="27" class="duo-icon-training"></v-icon>
								</v-avatar>
								<v-img v-else :src="$base + '/asset/file/' + page.valueMap.headerIcon" width="60" class="ml-auto"></v-img>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12">
				<v-card width="100%" min-height="416" rounded="0" :elevation="0" :style="[theme === 'purple' ? { backgroundColor: '#221551' } : theme === 'black' ? { backgroundColor: '#000000'} : { backgroundColor: '#FFFFFF' }]">
					<v-card-text class="ma-0 pa-0">
						<v-form v-model="validForm" ref="form" lazy-validation>
							<v-container class="d-flex align-center" style="height: auto">
								<v-row class="px-12 py-0">
									<v-col v-if="question" cols="12" class="d-flex justify-left align-center text-wrap my-4 mx-6">
										<span v-html="question" style="margin-left:-2vw; line-height:normal; font-size: 35px; font-family: AirNZ Black Italic; display: table; margin: auto; list-style-position: inside;" :style="[theme === 'white' ? { color: '#000000' } : { color: '#FFFFFF' }]"></span>
									</v-col>
									<v-item-group class="d-flex col-12 justify-space-between flex-wrap" v-model="optionSelected" :multiple="isMultipleAnswer">
											
										<!-- Answers 1 -->
										<div :class="'d-flex align-center px-0 py-2 ' + columnOption">
											<v-item v-slot="{ active, toggle }">
												<v-card
													class="d-flex align-center duo-custom white--text"
													style="width: 100%; border-radius: 4px; padding-left: 26px; color: #2D2926;"
													:style="isSelected && responseOptionId[0] == 1  ? (isCorrect ? 'border: 2px solid #04a0a8; ' : 'border: 2px solid #C73C09;') : 'border: 2px solid #C6C6C6;'"
													width="100%"
													height="100%"
													min-height="46"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, 1, page.valueMap.feedback, page.valueMap)"
												>
													<v-scroll-y-transition>
														<span v-if="!isSelected || (isSelected && responseOptionId[0] != 1)" style="font-size: 17px; display: table; margin: auto; list-style-position: inside;" v-html="answer1"></span>
														<span v-else style="font-size: 17px; width: 100%;">
															<v-row style="width: 100%;" class="full-width py-2">
																<v-col cols="auto" v-if="isSelected && responseOptionId[0] == 1">
																	<span v-if="isCorrect" style="color: #04a0a8;"><v-icon color="#04a0a8" title="That's right!">mdi-check-circle-outline</v-icon> <b>That's right!</b></span>
																	<span v-else style="color: #C73C09;"><v-icon color="#C73C09" title="Incorrect">mdi-close-circle-outline</v-icon> <b>Incorrect.</b> The correct answer is <div v-html="correctQuizAnswer"></div></span>
																</v-col>
																<v-col>
																	<div class="duo-custom" v-html="answerFeedback"></div>
																</v-col>
															</v-row>
														</span>
													</v-scroll-y-transition>
												</v-card>
											</v-item>
										</div>

										<!-- Answers 2 -->
										<div :class="'d-flex align-center px-0 py-2 ' + columnOption">
											<v-item v-slot="{ active, toggle }">
												<v-card
													class="d-flex align-center duo-custom white--text"
													style="width: 100%; border-radius: 4px; padding-left: 26px; color: #2D2926;"
													:style="isSelected && responseOptionId[0] == 2  ? (isCorrect ? 'border: 2px solid #04a0a8; ' : 'border: 2px solid #C73C09;') : 'border: 2px solid #C6C6C6;'"
													width="100%"
													height="100%"
													min-height="46"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, 2, page.valueMap.feedback, page.valueMap)"
												>
													<v-scroll-y-transition>
														<span v-if="!isSelected || (isSelected && responseOptionId[0] != 2)" style="font-size: 17px; display: table; margin: auto; list-style-position: inside;" v-html="answer2"></span>
														<span v-else style="font-size: 17px; width: 100%;">
															<v-row style="width: 100%;" class="full-width py-2">
																<v-col cols="auto" v-if="isSelected && responseOptionId[0] == 2 ">
																	<span v-if="isCorrect" style="color: #04a0a8;"><v-icon color="#04a0a8" title="That's right!">mdi-check-circle-outline</v-icon> <b>That's right!</b></span>
																	<span v-else style="color: #FFFFFF;"><v-icon color="#C73C09" title="Incorrect">mdi-close-circle-outline</v-icon> <b>Incorrect.</b> The correct answer is <div v-html="correctQuizAnswer"></div></span>
																</v-col>
																<v-col>
																	<div class="duo-custom" v-html="answerFeedback"></div>
																</v-col>
															</v-row>
														</span>
													</v-scroll-y-transition>
												</v-card>
											</v-item>
										</div>

										<!-- Answers 3 -->
										<div :class="'d-flex align-center px-0 py-2 ' + columnOption">
											<v-item v-slot="{ active, toggle }">
												<!-- :style="active ? (isCorrect ? 'border: 2px solid #04a0a8; ' : 'border: 2px solid #C73C09;') : 'border: 2px solid #C6C6C6;'" -->
												<v-card
													class="d-flex align-center duo-custom white--text"
													style="width: 100%; border-radius: 4px; padding-left: 26px; color: #2D2926;"
													:style="isSelected && responseOptionId[0] == 3 ? (isCorrect ? 'border: 2px solid #04a0a8; ' : 'border: 2px solid #C73C09;') : 'border: 2px solid #C6C6C6;'"
													width="100%"
													height="100%"
													min-height="46"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, 3, page.valueMap.feedback, page.valueMap)"
												>
													<v-scroll-y-transition>
														<!-- <span v-if="!active" style="font-size: 17px; display: table; margin: auto; list-style-position: inside;" v-html="answer3"></span> -->
														<span v-if="!isSelected || (isSelected && responseOptionId[0] != 3)" style="font-size: 17px; display: table; margin: auto; list-style-position: inside;" v-html="answer3"></span>
														<span v-else style="font-size: 17px; width: 100%;">
															<v-row style="width: 100%;" class="full-width py-2">
																<v-col cols="auto" v-if="isSelected && responseOptionId[0] == 3">
																	<span v-if="isCorrect" style="color: #04a0a8;"><v-icon color="#04a0a8" title="That's right!">mdi-check-circle-outline</v-icon> <b>That's right!</b></span>
																	<span v-else style="color: #FFFFFF;"><v-icon color="#C73C09" title="Incorrect">mdi-close-circle-outline</v-icon> <b>Incorrect.</b> The correct answer is <div v-html="correctQuizAnswer"></div></span>
																</v-col>
																<v-col>
																	<div class="duo-custom" v-html="answerFeedback"></div>
																</v-col>
															</v-row>
														</span>
													</v-scroll-y-transition>
												</v-card>
											</v-item>
										</div>

										<!-- Answers 4 -->
										<div :class="'d-flex align-center px-0 py-2 ' + columnOption">
											<v-item v-slot="{ active, toggle }">
												<v-card
													class="d-flex align-center duo-custom white--text"
													style="width: 100%; border-radius: 4px; padding-left: 26px; color: #2D2926;"
													:style="isSelected && responseOptionId[0] == 4  ? (isCorrect ? 'border: 2px solid #04a0a8; ' : 'border: 2px solid #C73C09;') : 'border: 2px solid #C6C6C6;'"
													width="100%"
													height="100%"
													min-height="46"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, 4, page.valueMap.feedback, page.valueMap)"
												>
													<v-scroll-y-transition>
														<span v-if="!isSelected || (isSelected && responseOptionId[0] != 4)" style="font-size: 17px; display: table; margin: auto; list-style-position: inside;" v-html="answer4"></span>
														<span v-else style="font-size: 17px; width: 100%;">
															<v-row style="width: 100%;" class="full-width py-2">
																<v-col cols="auto" v-if="isSelected && responseOptionId[0] == 4 ">
																	<span v-if="isCorrect" style="color: #04a0a8;"><v-icon color="#04a0a8" title="That's right!">mdi-check-circle-outline</v-icon> <b>That's right!</b></span>
																	<span v-else style="color: #FFFFFF;"><v-icon color="#C73C09" title="Incorrect">mdi-close-circle-outline</v-icon> <b>Incorrect.</b> The correct answer is <div v-html="correctQuizAnswer"></div></span>
																</v-col>
																<v-col>
																	<div class="duo-custom" v-html="answerFeedback"></div>
																</v-col>
															</v-row>
														</span>
													</v-scroll-y-transition>
												</v-card>
											</v-item>
										</div>

									</v-item-group>
								</v-row>
							</v-container>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
			
			<v-col cols="12" class="d-flex justify-end">
				<v-row class="d-flex flex-wrap">
					<v-col cols="12" class="d-flex justify-end pr-0" style="margin-bottom: 13vw;">
						<v-btn style="background-color:#04A0A8; border-color: #04A0A8;" :disabled="!validForm || !isSubmitted" height="50" width="193" elevation="0" outlined class="white--text ml-auto text-transform-none" :right="true" @click="nextPage()">Next</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		
	</v-container>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		},
		bgColor: {
			type: String,
			required: true
		},

		//

		question: {
			type: String,
			required: true
		},
		answer1: {
			type: String,
			required: true
		},
		answer2: {
			type: String,
			required: true
		},
		answer3: {
			type: String,
			required: true
		},
		answer4: {
			type: String,
			required: true
		},
		correctAnswer: {
			type: Number,
			required: true
		},

		isCorrect: {
			type: Boolean,
			default: null,
			required: false
		},
		isSelected: {
			type: Boolean,
			default: null,
			required: false
		},

		//

	},
	computed: {
		parsedHeaderBackgroundColor() {
			if (this.page.valueMap.headerBackgroundColor) {
				return this.page.valueMap.headerBackgroundColor.hexa;
			} else {
				return "#2D2926";
			}
		},
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa;
			} else {
				return "#FFFFFFFF";
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa;
			} else {
				return "#440099";
			}
		},
		columnOption() {
			if (this.page.type == "multiple-choice-1") {
				// horizontal
				return "col-3";
			} else if (this.page.type == "multiple-choice-2" || this.page.type == "new-quiz-2") {
				// vertical
				return "col-12";
			} else {
				// horizontal
				return "col-2";
			}
		},
		isMultipleAnswer() {
			return this.getActualCorrectId().length > 1;
		}
	},
	data: function () {
		return {
			validForm: true,
			isSubmitted: false,
			answerFeedback: null,
			responseOptionId: [],
			optionSelected: null,
			correctQuizAnswer: "",
		};
	},
	methods: {
		validateAnswer(responseOptionId, valueMap) {
			this.isSubmitted = true;
			this.isSelected = true;
			var actualCorrectId = this.getActualCorrectId();

			this.correctQuizAnswer = actualCorrectId == 1 ? valueMap.answer1 : actualCorrectId == 2 ? valueMap.answer2 : actualCorrectId == 3 ? valueMap.answer3 : valueMap.answer4

			this.isCorrect = actualCorrectId == responseOptionId[0] ? true : false
		},
		getActualCorrectId() {
			return  this.page.valueMap.correctAnswer ? this.page.valueMap.correctAnswer : 0
		},
		submit(valueMap) {
			if (this.$refs.form.validate()) {
				this.validateAnswer(this.responseOptionId, valueMap);

				if (!this.isPreview) {
					this.page.valueMap.responseOptionId = this.responseOptionId;
					this.$emit("submit");
				}
			}
		},
		save() {
			axios
				.post("/system/page/edit", { page: this.page })
				.then(() => {})
				.catch(() => {});
		},
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.validForm = true;
				this.isSubmitted = false;
				this.isSelected = false;
				this.isCorrect = null;
				this.answerFeedback = null;
				this.responseOptionId = [];
				this.optionSelected = null;
				this.$emit("nextPage");
			}
		},
		onClickOption(toggle, active, optionId, feedback, valueMap) {
			if (!this.isSelected) {
				toggle();
				if (active) {
					// only one answer
					if (this.getActualCorrectId().length === 1) {
						this.responseOptionId = [];
						this.responseOptionId.push(optionId);
					} else {
						if (this.responseOptionId.indexOf(optionId) === -1) {
							this.responseOptionId.push(optionId);
						}
					}
				} else {
					this.responseOptionId =	this.responseOptionId.filter(option => option !== optionId);
				}
				this.isSelected = !this.$root.isBlank(this.responseOptionId) ? true : false;
				this.answerFeedback = feedback;
				this.submit(valueMap)
			}
		}
	}
};
</script>

<style scoped>
.transparent-card {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.form-group {
	height: 80vh;
}
.radio-question .v-label {
	font-size: 20px !important;
}
.radio-question .error--text {
	font-size: 20px !important;
}
.v-application .title {
	font-size: 26px !important;
	font-weight: 600;
	letter-spacing: 1px !important;
}
.correct-answer {
	color: green;
	font-size: 20px;
	font-weight: bold;
}
.wrong-answer {
	color: red;
	font-size: 20px;
	font-weight: bold;
}
.question-answer {
	border-left: 2px solid rgba(224, 224, 224, 0.5);
}
</style>