<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center mt-5" style="margin-bottom: -1.5vw;" >
			<v-row class="d-flex flex-wrap duo-custom-template-full">
			<v-col cols="12" v-if="!isPreview">
				<v-row class="d-flex justify-space-between align-center">
					<v-col class="duo-custom justify-left align-center">
						<div style="font-family: AirNZ Sans Regular !important; color: #FFFFFF;" class="pl-10"><h2>{{ title }}</h2></div>
					</v-col>
					<v-col cols="auto" class="justify-right">
						<v-row class="d-flex justify-right no-gutters mr-10">
							<v-col class="duo-custom pr-3 d-flex align-center">
								<div v-html="page.valueMap.headerIconTitle" class="text-right align-center" style="font-family: AirNZ Sans Regular !important; color: #FFFFFF;"></div>
							</v-col>
							<v-col cols="auto" class="d-flex align-end">
								<v-avatar v-if="$root.isBlank(page.valueMap.headerIcon)" :color="parsedHeaderIconBackgroundColor" size="58" class="ml-auto">
									<v-icon :color="parsedHeaderIconColor" size="27" class="duo-icon-training"></v-icon>
								</v-avatar>
								<v-img v-else :src="$base + '/asset/file/' + page.valueMap.headerIcon" width="60" class="ml-auto"></v-img>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12">
				<v-row no-gutters>
					<!-- Body -->
					<v-col cols="12" >
						<v-card width="100%" min-height="75vh" :elevation="0" tile :style="[bgColor === 'purple' ? { backgroundColor: '#221551' } : bgColor === 'black' ? { backgroundColor: '#000000'} : { backgroundColor: '#FFFFFF' }]">
							<v-card-text class="pa-12">
								<div
									class="mt-10" 
									style="margin-left: 14vw; margin-right: 14vw; line-height:normal; text-align: center; font-family: 'AirNZ Black Italic', sans-serif; color: #000000; font-size: 45px;" 
									:style="[bgColor === 'white' ? { color: '#000000' } : { color: '#FFFFFF' }]">
									<div v-html="page.valueMap.headerTitle" style="display: table; margin: auto; list-style-position: inside;"></div>
								</div>
								<div v-html="page.valueMap.bodyCopy" class="text-wrap duo-custom-link pt-10" style="display: table; margin: auto; list-style-position: inside; max-width: fit-content; margin-inline: auto; width:70%; line-height: 21px; font-family: AirNZ Sans Regular !important; padding-top: 20px;" :style="[theme === 'white' ? { color: '#000000' } : { color: '#FFFFFF' }]"></div>
								<v-row justify="center" class="pt-10">
									<span>
										<v-btn depressed height="50" width="200" color="#00B0B9" class="white--text ml-auto mt-3 duo-text-button" @click="nextPage()">{{ page.valueMap.buttonText }}</v-btn>
									</span>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		},
		bgColor: {
			type: String,
			required: true
		}
	},
	computed: {
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa;
			} else {
				// white
				return "#FFFFFFFF";
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa;
			} else {
				// purple
				return "#221551";
			}
		}
	},
	methods: {
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.$emit("nextPage");
			}
		}
	}
};
</script>

<style scoped>
.transparent-card {
	background-color: rgba(255, 255, 255, 0.85) !important;
}
.text-transform-none.v-btn {
	text-transform: none !important;
}
</style>