<template>
    <v-main>
        <v-card color="#1f1f1f" class="py-3 px-5" flat fluid rounded="0">
            <v-row align="center" justify="space-between">
                <v-col cols="12" sm="6" class="text-left">
                    <img style="width: 200px; margin-top: 5px;" :src="$bucket + '/image/logo-duo.svg'" />
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                    <img style="width: 200px; " :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
                </v-col>
            </v-row>
            <v-row align="center" justify="space-between">
                <v-col cols="6">
                    <h2 class="white--text" style="font-family: AirNZ Sans Semibold !important;">
                        {{ operation == "create" ? "New" : "Edit" }} training module
                    </h2>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-btn outlined class="mr-2 cancel-btn white--text" height="42" width="140" @click="cancel">Cancel</v-btn>
                    <v-btn :color="isSaveButtonDisabled ? 'grey' : '#01b0b9'" class="white--text" height="42" width="140" @click="save" :ripple="isSaveButtonDisabled ? false : true">Save</v-btn>
                </v-col>
            </v-row>
            <v-row align="center" justify="space-between">
                <v-col cols="12">
                    <v-tabs dark v-model="trainingTab" background-color="#1f1f1f" color="white" style="border-bottom: 1px solid white;">
                        <v-tab v-for="item in iTab" :key="item.value" >{{ item.text }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="trainingTab">

                        <!-- Details  -->
                        <v-tab-item>
                            <v-card color="#1f1f1f" class="pt-4 pl-2" height="100vh">
                                <v-row align="center" style="width: 800px;">
                                    <v-col>
                                        <v-row align="center" justify="start" style="width: 200px;">
                                            <v-col>
                                                <div class="white--text">{{ formFields.active ? 'Active' : 'Inactive' }}</div>
                                            </v-col>
                                            <v-col align-self="start">
                                                <v-switch
                                                    v-model="formFields.active"
                                                    color="teal"
                                                    inset
                                                    class="white--text"
                                                    dark
                                                    @blur="saveDetailsToState"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row align="center" justify="start">
                                    <v-col>
                                        <v-text-field
                                            autofocus
                                            label="Title"
                                            id="title"
                                            v-model="formFields.name"
                                            class="white--text"
                                            dark
                                            :rules="[(v) => !!v || 'This field is required']"
                                            @blur="saveDetailsToState"
                                        ></v-text-field>
                                        <v-text-field
                                            label="Description"
                                            v-model="formFields.description"
                                            class="white--text"
                                            dark
                                            @blur="saveDetailsToState"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>

                        <!-- Slides -->
                        <v-tab-item>
                            <v-card color="#1f1f1f" class="pt-4 pl-2" height="100vh" id="tab-content">
                                <v-row align="center" justify="space-between">
                                    <v-col cols="9" >
                                        <h2 class="white--text pl-5" style="font-family: AirNZ Sans Semibold !important;">Slides</h2>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col>
                                        <v-btn color="#440099" class="white--text" height="42" width="120" @click="$router.push({path: '/administration/training/module/create/slide'})">Add new</v-btn>
                                    </v-col>
                                </v-row>

                                <!-- List of Slide -->
                                <draggable v-bind="dragOptions" v-model="$store.state.trainingModule.slides" class="row justify-start" style="flex-grow: 1; overflow: auto;" >
                                    <v-col cols="4" class="my-0 py-0" v-for="(page, index) in $store.state.trainingModule.slides" :key="index + 0">
                                    <v-card dark flat rounded="0" height="100%" @click="editSlide(page, index, formFields.name)">
                                        <v-card-text class="ma-0 pa-0" style="cursor: move;">
                                        <div v-for="pageType in slideTypeStaticData" :key="pageType.type">
                                            <v-img v-if="page.type === pageType.type" :src="pageType.srcImage" contain eager
                                            style="border: 1px solid white;"></v-img>
                                        </div>
                                        </v-card-text>
                                        <v-card-actions>
                                        <v-row class="d-flex" justify="space-between">
                                            <v-col cols="10">
                                            <div v-for="pageType in slideTypeStaticData" :key="pageType.type">
                                                {{ validateTitle(page, pageType, index) }}
                                            </div>
                                            </v-col>
                                            <v-col cols="2">
                                            <v-btn fab right x-small elevation="1" style="right: -8px;" @click.stop="showDeleteItem(page)">
                                                <v-icon class="icon-tm-delete pb-2" size="14"></v-icon>
                                            </v-btn>
                                            </v-col>
                                        </v-row>
                                        </v-card-actions>
                                    </v-card>
                                    </v-col>
                                </draggable>

                            </v-card>
                        </v-tab-item>

                        <!-- Quizes -->
                        <v-tab-item>
                            <v-card color="#1f1f1f" class="pt-4 pl-2 hideScrollbar" height="100vh" id="tab-content">
                                <v-row align="center" justify="space-between">
                                    <v-col cols="9" >
                                        <h2 class="white--text pl-5" style="font-family: AirNZ Sans Semibold !important;">Quiz List</h2>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col>
                                        <v-btn color="#440099" class="white--text" height="42" width="120" @click="$router.push({path: '/administration/training/module/create/quiz'})">Add new</v-btn>
                                    </v-col>
                                </v-row>

                                <!-- List of Quiz -->
                                <v-data-table dark ref="myTable" v-model="selectedQuiz"
                                    :items="$store.state.trainingModule.quizes" 
                                    :headers="fieldList"
                                    item-key="title" class="elevation-1">

                                    <template #body="props">
                                        <draggable :list="props.items" tag="tbody" :move="onMoveCallback" :clone="onCloneCallback" @end="onDropCallback">
                                            <data-table-row-handler v-for="(item, index) in props.items" :key="index" 
                                                :item="item" :headers="fieldList">
                                                
                                                 <!-- Replacing the Question column with v-html -->
                                                <template v-slot:item.question="{ item }">
                                                    <div v-html="item.question"></div>
                                                </template>

                                                <template v-slot:item.action="{ item }">
                                                    <div class="d-flex flex-row">

                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                            <v-icon size="18" class="mr-2 icon-tm-quick-edit" v-on="{ ...tooltip }" @click="editQuiz(item)
                                                                "></v-icon>
                                                            </template>
                                                            <span>Edit</span>
                                                        </v-tooltip>

                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                            <v-icon size="18" class="ml-4 icon-tm-delete" v-on="{ ...tooltip }" @click="showDeleteItem(item)
                                                                "></v-icon>
                                                            </template>
                                                            <span>Delete</span>
                                                        </v-tooltip>

                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip }">
                                                            <v-icon size="18" class="ml-4 " v-on="{ ...tooltip }">mdi-drag</v-icon>
                                                            </template>
                                                            <span>Drag</span>
                                                        </v-tooltip>
                                                    </div>
                                                </template>
                                                <template v-slot:no-data>Empty list</template>
                                            </data-table-row-handler>
                                        </draggable>
                                    </template>
                                    
                                </v-data-table>

                                

                                <!-- <draggable v-bind="dragOptions" v-model="$store.state.trainingModule.quizes" class="row justify-start">
                                    <v-col cols="4" class="my-0 py-0" v-for="(page, index) in $store.state.trainingModule.quizes" :key="index + 0">
                                    <v-card dark flat rounded="0" height="100%" @click="editQuiz(page)">
                                        <v-card-text class="ma-0 pa-0" style="cursor: move;">
                                        <div v-for="pageType in quizTypeStaticData" :key="pageType.type">
                                            <v-img v-if="page.type === pageType.type" :src="pageType.srcImage" contain eager
                                            style="border: 1px solid white;"></v-img>
                                        </div>
                                        </v-card-text>
                                        <v-card-actions>
                                        <v-row class="d-flex" justify="space-between">
                                            <v-col cols="10">
                                            <div v-for="pageType in quizTypeStaticData" :key="pageType.type">
                                                {{ validateTitle(page, pageType, index) }}
                                            </div>
                                            </v-col>
                                            <v-col cols="2">
                                            <v-btn fab right x-small elevation="1" style="right: -8px;" @click.stop="showDeleteItem(page)">
                                                <v-icon class="icon-tm-delete pb-2" size="14"></v-icon>
                                            </v-btn>
                                            </v-col>
                                        </v-row>
                                        </v-card-actions>
                                    </v-card>
                                    </v-col>
                                </draggable> -->

                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="dialogItemDelete" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Delete {{ deleteItem.type }}</span>
                </v-card-title>
                <v-card-text>Are you sure you want to delete {{ deleteItem.type }} '{{ removeHtmlTag(deleteItem.name) }}' ?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialogItemDelete = false">Cancel</v-btn>
                    <v-btn @click="doItemDelete()" color="accent">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<style scoped>
#tab-content {
    max-height: 100vh; /* Set the desired height */
    overflow-y: auto;  /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
}
 /* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
} 
</style>

<script>
/* eslint-disable no-console */
import axios from "axios";
import draggable from "vuedraggable";
import DataTableRowHandler from "./DataTableRowHandler.vue";
export default {
    components: {
        draggable,
        DataTableRowHandler
    },
    data: function() {
        return {
            selectedQuiz: [],
            fieldList: [
				{ text: "Question", value: "question", align:"left", width: "100%" },
				{ text: "", value: "action", sortable: false }
			],
            operation: "edit",
            trainingTab: 0,
            iTab: [
                {text: 'Details', value: 'details'},
                {text: 'Slides', value: 'slides'},
                {text: 'Quizzes', value: 'quizes'}
            ],
            formFields: {
                id: null,
                active: true,
                name: '',
                description: ''
            },
            quizTypeStaticData: [
                { type: "new-quiz-1", title: "Quiz 1", srcImage: "/template/new-quiz-1.jpg" },
                { type: "new-quiz-2", title: "Quiz 2", srcImage: "/template/new-quiz-2.jpg" },
            ],
            slideTypeStaticData: [
                { type: "start-1", title: "Start 1 Page", srcImage: "/template/anz-training-start-1.jpg" },
                { type: "start-2", title: "Start 2 Page", srcImage: "/template/anz-training-start-2.jpg" },
                { type: "start-3", title: "Start 3 Page", srcImage: "/template/anz-training-start-3.jpg" },
                { type: "info-image-1", title: "Information Image 1 Page", srcImage: "/template/anz-training-info-image-1.jpg" },
                { type: "info-image-2", title: "Information Image 2 Page", srcImage: "/template/anz-training-info-image-2.jpg" },
                { type: "info-image-3", title: "Information Image 3 Page", srcImage: "/template/anz-training-info-image-3.jpg" },
                { type: "info-image-4", title: "Information Image 4 Page", srcImage: "/template/anz-training-info-image-4.jpg" },
                { type: "info-image-5", title: "Information Image Full Page", srcImage: "/template/anz-training-info-image-5.jpg" },
                { type: "info-video-1", title: "Information Video 1 Page", srcImage: "/template/anz-training-info-video-1.jpg" },
                { type: "info-video-2", title: "Information Video 2 Page", srcImage: "/template/anz-training-info-video-2.jpg" },
                { type: "info-text-1", title: "Information Text 1 Page", srcImage: "/template/anz-training-info-text-1.jpg" },
                { type: "info-text-2", title: "Information Text 2 Page", srcImage: "/template/anz-training-info-text-2.jpg" },
                { type: "multiple-choice-1", title: "Multiple Choice 1 Page", srcImage: "/template/anz-training-question-multiple-choice-1.jpg" },
                { type: "multiple-choice-2", title: "Multiple Choice 2 Page", srcImage: "/template/anz-training-question-multiple-choice-2.jpg" },
                { type: "short-answer", title: "Short Answer Page", srcImage: "/template/anz-training-question-short-answer.jpg" },
                { type: "free-answer", title: "Free Answer Page", srcImage: "/template/anz-training-question-short-answer.jpg" },

                { type: "new-training-", title: "Slide Title", srcImage: "/template/new-training.jpg" },

                { type: "new-training-1", title: "Training 1", srcImage: "/template/new-training-1.jpg" },
                { type: "new-training-2", title: "Training 2", srcImage: "/template/new-training-2.jpg" },
                { type: "new-training-3", title: "Training 3", srcImage: "/template/new-training-3.jpg" },
                { type: "new-training-4", title: "Training 4", srcImage: "/template/new-training-4.jpg" },
                { type: "new-training-5", title: "Training 5", srcImage: "/template/new-training-5.jpg" },
                { type: "new-training-6", title: "Training 6", srcImage: "/template/new-training-6.jpg" },
                { type: "new-training-7", title: "Training 7", srcImage: "/template/new-training-7.jpg" },
            ],
            dialogItemDelete : false,
            deleteItem: {
                name : '',
                type : ''
            },
            page: {
				valueMap: {
					headerTitle: "",

                    bodyCopy: "",
                    buttonText: "",
                    slideLayout: 0,
                    backgroundColor: "black",
                    isDisplayAfterQuiz: false,
                    type: '',
                    name: ''
				}
			},
            pageList: [],
            quizList: [],
            quiz: {
                valueMap: {
					question: "",

                    imageNameQuestion: "",
                    answer1: "",
                    answer2: "",
                    answer3: "",
                    answer4: "",
                    imageNameAnswer1: "",
                    imageNameAnswer2: "",
                    imageNameAnswer3: "",
                    imageNameAnswer4: "",

                    quizLayout: 0,
                    backgroundColor: "black",
                    type: '',
                    name: ''
				}
            }
        }
    },
    created() {
		this.operation = this.$route.params.operation;
        this.pushQuiz()
	},
    computed: {
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},
        isSaveButtonDisabled() {
            return !(this.formFields.name);
        },
	},
    mounted: function() {
		if (this.operation == "edit" && this.$store.state.trainingModule.id != null) {

            this.formFields.id = this.$store.state.trainingModule.id
            
        }

        this.formFields.active = this.$store.state.trainingModule.details.isActive
        this.formFields.name = this.$store.state.trainingModule.details.name
        this.formFields.description = this.$store.state.trainingModule.details.description


	},
    methods: {
        removeHtmlTag(item) {
            return item.replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;","");
        },
        onCloneCallback(item) {
            const cloneMe = JSON.parse(JSON.stringify(item))
            return cloneMe
        },
        onMoveCallback(evt, originalEvent) {
            const item = evt.draggedContext.element;
            const itemIdx = evt.draggedContext.futureIndex;

            console.log("onMoveCallback ", originalEvent)

            let module = {...this.$store.state.trainingModule}
            
            let quizes = module.quizes
            
            let targetQuiz = quizes.find(map => map.question === item.question)
            
            quizes.splice(itemIdx, 0, quizes.splice(quizes.indexOf(targetQuiz), 1)[0]);
            
            module.quizes = quizes
            
            this.$store.commit("setTrainingModule", module)

            if (item.locked) {
                return false;
            }

            return true
        },
        onDropCallback(evt, originalEvent) {
            console.log(evt)
            console.log("onDropCallback ", originalEvent);
        },
        saveDetailsToState() {
            let module = {...this.$store.state.trainingModule}
            module.details.isActive = this.formFields.active
            module.details.name = this.formFields.name
            module.details.description = this.formFields.description

            this.$store.commit("setTrainingModule", module)
        },
        createNewSlide() {
            this.$router.push({path: "/administration/training/module/create/slide"})
        },
        cancel() {
            this.$store.commit("resetTrainingModule")
            this.$router.push({path: "/administration/training/list/module"})
        },

        pushSlide() {
            this.pageList = this.$store.state.trainingModule.slides.map(slide => {
                let newPage = JSON.parse(JSON.stringify(this.page)); // Deep copy of this.page
                newPage.type = slide.type;
                newPage.name = slide.type;
                newPage.valueMap.slideLayout = slide.layout;
                newPage.valueMap.backgroundColor = slide.backgroundColor;
                newPage.valueMap.headerTitle = slide.heading;
                newPage.valueMap.bodyCopy = slide.bodyCopy;
                newPage.valueMap.buttonText = slide.buttonText;
                newPage.valueMap.isDisplayAfterQuiz = slide.isDisplayAfterQuiz;
                newPage.valueMap.imageName = slide.imageName;
                return newPage;
            });
        },
        pushQuiz() {
            this.quizList = this.$store.state.trainingModule.quizes.map(quiz => {
                let newPage = JSON.parse(JSON.stringify(this.page)); // Deep copy of this.page
                newPage.type = quiz.type;
                newPage.name = quiz.type;
                newPage.valueMap.backgroundColor = quiz.backgroundColor;
                newPage.valueMap.quizLayout = quiz.layout;

                newPage.valueMap.question = quiz.question
                newPage.valueMap.imageNameQuestion = quiz.imageNameQuestion
                newPage.valueMap.answer1 = quiz.answer1
                newPage.valueMap.answer2 = quiz.answer2
                newPage.valueMap.answer3 = quiz.answer3
                newPage.valueMap.answer4 = quiz.answer4
                newPage.valueMap.imageNameAnswer1 = quiz.imageNameAnswer1
                newPage.valueMap.imageNameAnswer2 = quiz.imageNameAnswer2
                newPage.valueMap.imageNameAnswer3 = quiz.imageNameAnswer3
                newPage.valueMap.imageNameAnswer4 = quiz.imageNameAnswer4

                newPage.valueMap.correctAnswer = quiz.correctAnswer
                
                return newPage;
            });
        },
        
        async save() {

            if (!this.isSaveButtonDisabled) {

                this.pageList.length = 0
                this.quizList.length = 0

                // update vuex state
                let module = {...this.$store.state.trainingModule}
                module.details.isActive = this.formFields.active
                module.details.name = this.formFields.name
                module.details.description = this.formFields.description
                this.$store.commit("setTrainingModule", module)

                this.pushSlide()
                this.pushQuiz()

                // add training module to DB
                if (module.id == null) {
                    let moduleId = 0

                    await axios
                    .post("/api/trainingModule/add", { trainingModule: this.formFields })
                    .then((response) => {
                        moduleId = response.data.id
                        // axios.post("/api/page/add/bulk", {pages: this.pageList, moduleId: moduleId})
                    })
                    .catch((e) => { console.log("Error add : ", e.message)})

                    // await axios.post("/api/page/add/bulk", {pages: this.pageList, moduleId: moduleId})

                    // save all slides
                    await axios
                        .post("/api/page/add/bulk", {pages: this.pageList, moduleId: moduleId})
                        .catch((e) => { console.log("Error add slide bulk: ", e.message)})

                    // save all quizzes
                    await axios
                        .post("/api/page/add/bulk", {pages: this.quizList, moduleId: moduleId})
                        .catch((e) => { console.log("Error add quiz bulk: ", e.message)})

                } else { 

                    // edit existing training module
                    await axios
                        .post("/api/trainingModule/edit", { trainingModule: this.formFields })
                        .catch((e) => { console.log("Error edit : ", e.message)})

                    // remove all slides and quizzes related to this module id
                    await axios
                        .post("/api/page/module/delete", { moduleId: module.id })
                        .catch((e) => { console.log("Error delete : ", e.message)})

                    // save all slides
                    await axios
                        .post("/api/page/add/bulk", {pages: this.pageList, moduleId: module.id})
                        .catch((e) => { console.log("Error add slide bulk: ", e.message)})

                    // save all quizzes
                    await axios
                        .post("/api/page/add/bulk", {pages: this.quizList, moduleId: module.id})
                        .catch((e) => { console.log("Error add quiz bulk: ", e.message)})

                }

                await this.$store.commit("resetTrainingModule")
                await this.$router.push({path: "/administration/training/list/module"})
            } 
            
        },
        validateTitle(data, pageType, index) {
            var title = pageType.title;
            var header = data.heading ? data.heading : data.question ? data.question : null;
            if (data.type != pageType.type) {
                return "";
            }
            if (header) {
                var div = document.createElement("div");
                div.innerHTML = header;
                title = div.textContent || div.innerText || "";
            }
            return (index + 1) + ". " + title;
        },
        showDeleteItem(item) {
            this.deleteItem.name = item.type.includes("training") ? item.heading : item.question
            this.deleteItem.type = item.type.includes("training") ? "slide" : "quiz"
			this.dialogItemDelete = true;
		},
        doItemDelete() {
            let module = {...this.$store.state.trainingModule}

            if (this.deleteItem.type == "slide") {

                let newSlides = module.slides.filter(item => item.heading !== this.deleteItem.name)

                // reset slides
                module.slides.length = 0

                // set new array of slides
                module.slides = [...newSlides]

            } else {    //quiz

                let newQuizes = module.quizes.filter(item => item.question !== this.deleteItem.name)

                // reset quiz
                module.quizes.length = 0

                // set new array of quizes
                module.quizes = [...newQuizes]
            }

            this.$store.commit("setTrainingModule", module)

            this.dialogItemDelete = false;
            
        },
        editSlide(item, index, title) {

            if (this.$store.state.trainingModule.id != null ) {

                this.$store.commit("resetChoosenItem")
                let slide = {...this.$store.state.choosenItem}

                if (item.valueMap === undefined) {
                    slide.previousId = item.heading
                    slide.layout = item.layout
                    slide.backgroundColor = item.backgroundColor
                    slide.heading = item.heading
                    slide.bodyCopy = item.bodyCopy
                    slide.buttonText = item.buttonText
                    slide.isDisplayAfterQuiz = item.isDisplayAfterQuiz
                    slide.type = item.type
                    slide.name = item.name
                    slide.imageName = item.imageName
                } else {
                    slide.previousId = item.valueMap.headerTitle
                    slide.layout = item.valueMap.slideLayout
                    slide.backgroundColor = item.valueMap.backgroundColor
                    slide.heading = item.valueMap.headerTitle
                    slide.bodyCopy = item.valueMap.bodyCopy
                    slide.buttonText = item.valueMap.buttonText
                    slide.isDisplayAfterQuiz = item.valueMap.isDisplayAfterQuiz
                    slide.type = item.valueMap.type
                    slide.name = item.valueMap.name
                    slide.imageName = item.valueMap.imageName
                }
                slide.title = title

                this.$store.commit("setChoosenItem", slide)

            } else {

                item.previousId = item.heading
                this.$store.commit("setChoosenItem", item)
            }

            this.$router.push({path: "/administration/training/module/edit/slide"})
        },
        editQuiz(item) {
            item.previousId = item.question
            this.$store.commit("setChoosenItem", item)

            if (this.$store.state.trainingModule.id != null ) {

                this.$store.commit("resetChoosenItem")
                let quiz = {...this.$store.state.choosenItem}

                if (item.valueMap === undefined) {
                    quiz.previousId = item.question
                    quiz.layout = item.quizLayout
                    quiz.backgroundColor = item.backgroundColor
                    quiz.type = item.type
                    quiz.name = item.name

                    quiz.question = item.question
                    quiz.answer1 = item.answer1
                    quiz.answer2 = item.answer2
                    quiz.answer3 = item.answer3
                    quiz.answer4 = item.answer4
                    quiz.imageNameQuestion = item.imageNameQuestion
                    quiz.correctAnswer = item.correctAnswer
                    quiz.imageNameAnswer1 = item.imageNameAnswer1
                    quiz.imageNameAnswer2 = item.imageNameAnswer2
                    quiz.imageNameAnswer3 = item.imageNameAnswer3
                    quiz.imageNameAnswer4 = item.imageNameAnswer4


                } else {
                    quiz.previousId = item.valueMap.question
                    quiz.layout = item.quizLayout
                    quiz.backgroundColor = item.valueMap.backgroundColor
                    quiz.type = item.valueMap.type
                    quiz.name = item.valueMap.name

                    quiz.question = item.valueMap.question
                    quiz.answer1 = item.valueMap.answer1
                    quiz.answer2 = item.valueMap.answer2
                    quiz.answer3 = item.valueMap.answer3
                    quiz.answer4 = item.valueMap.answer4
                    quiz.imageNameQuestion = item.valueMap.imageNameQuestion
                    quiz.correctAnswer = item.valueMap.correctAnswer
                    quiz.imageNameAnswer1 = item.valueMap.imageNameAnswer1
                    quiz.imageNameAnswer2 = item.valueMap.imageNameAnswer2
                    quiz.imageNameAnswer3 = item.valueMap.imageNameAnswer3
                    quiz.imageNameAnswer4 = item.valueMap.imageNameAnswer4
                }

                this.$store.commit("setChoosenItem", quiz)

            } else {

                item.previousId = item.question
                this.$store.commit("setChoosenItem", item)
            }

            
            this.$router.push({path: "/administration/training/module/edit/quiz"})
        }
    }
}
</script>
